export enum FilterType {
  Single = 'SINGLE',
  Multiple = 'MULTIPLE',
  Boolean = 'BOOLEAN',
  Hierarchy = 'HIERARCHY',
  ScoreBool = 'SCORE_BOOL',
}

export enum FilterOptionColor {
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum FilterColor {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

// export enum FilterOptionColorFG {
//   Info = '#344054',
//   Success = '#027A48',
//   Warning = '#B54708',
// }

// export enum FilterOptionColorBG {
//   Info = '#FFFFFF',
//   Success = '#ECFDF3',
//   Warning = '#FFFAEB',
// }

// export enum FilterOptionColorIcon {
//   Info = '#344054',
//   Success = '#12B76A',
//   Warning = '#F79009',
// }
